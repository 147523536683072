.MuiTable-root tr td:last-child div {
	justify-content: flex-end;
}

.MuiTableBody-root tr:first-child td:last-child {
	background: white;
	padding: 4px 16px;
	position: sticky;
	z-index: 99;
	top: 25px;
}
